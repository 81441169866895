


window.addEventListener('load', function(){

    var shop_list = document.querySelector(".box-list");
    var shop_name = document.getElementsByClassName("js-box");  

    if(shop_list !== null && shop_name.length > 0){
        for(var i = 0; i < shop_name.length; i++){
            var new_div = document.createElement('div');
            new_div.setAttribute("style", "height:0; width:" + shop_name[i].clientWidth   + "px; margin:0 ;");
            shop_list.appendChild(new_div);
        }
    }
});